import React from 'react';
import { Helmet } from "react-helmet";

function Guidelines() {

  return (
    <>
      <Helmet>
        <title>Careem - Vulnerability Disclosure Guidelines</title>
      </Helmet>
      <div className="container py-4">
        <h1>Vulnerability Disclosure Guidelines</h1>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <h2>Rules and Terms</h2>
            <p>We ask that as a Security Researcher, you:</p>
            <ul>
              <li>Abide by these Careem Responsible Disclosure Program Terms.</li>
              <li>Do not leave any system in a more vulnerable state than you found it.</li>
              <li>Do not publicly disclose a Vulnerability without our consent and review.</li>
              <li><strong className="careem-regular">Do no harm</strong>. Act for the common good through the prompt reporting of all found Vulnerabilities.</li>
              <li><strong className="careem-regular">Respect privacy</strong>. Make a good faith effort not to access, process or destroy personal data.</li>
              <li><strong className="careem-regular">Be patient</strong>. Make a good faith effort to provide clarifications to any questions  we may have on the Vulnerabilities that you report.</li>
              <li>Be respectful when interacting with our team, and our team will do the same.</li>
              <li>Once you submit a report, our Security team will act rapidly to tirage and categorize reported issues. We commit that we will respect your time, and recognize those who help us better our security. We will make every effort to respond to valid reports within seven business days.</li>
            </ul>
          </div>
          <div className="col-md-6">
            <h2>Submissions and Report Quality Checklist</h2>
            <ul>
              <li>Scope: Check the scope page before you begin writing your report to ensure the issue you are reporting is in scope for the Responsible Disclosure Program.</li>
              <li>Think through the attack scenario and exploitability of the Vulnerability and provide as many clear details as possible (see suggested template below) for our team to reproduce the issue (include screenshots if possible).</li>
              <li>The report must demonstrate security impact to a site or application in scope.</li>
              <li>Video only proofs-of-concept (PoCs) will not be considered.</li>
              <li>A Vulnerability must be verifiable and reproducible for us to be considered in-scope.</li>
              <li>Moving beyond minimally necessary proof of concept for server-side execution issues is prohibited.</li>
              <li>Social engineering activities against Careem employees, users/customers and captains are prohibited. </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div class="col-md-6">
            <h2>Scope</h2>
            <ul>
              <li>
                Services exposed on the following domains, with the exception of hosted third-party tools
                <ul>
                  <li><code>*.careem.com</code></li>
                  <li><code>*.careem-engineering.com</code></li>
                  <li><code>*.careem-internal.com</code></li>
                </ul>
              </li>
              <li>
                Mobile Applications
                <ul>
                  <li>
                    Android
                    <ul>
                      <li>Careem - Car Booking App (<code>com.careem.acma</code>)</li>
                      <li>Careem NOW (<code>com.roundmenu</code>)</li>
                      <li>Careem Captain (<code>com.careem.adma</code>)</li>
                      <li>Careem BUS (<code>com.careem.bus.customer</code>)</li>
                      <li>Captain - Careem BUS (<code>com.careem.bus.captain</code>)</li>
                    </ul>
                  </li>
                  <li>
                    iOS
                    <ul>
                      <li>Careem - Car Booking App (<code>id592978487</code>)</li>
                      <li>Careem NOW (<code>id533698444</code>)</li>
                      <li>Careem Captain (<code>id1356111909</code>)</li>
                      <li>Careem BUS (<code>id1440591385</code>)</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <h2>Out of scope vulnerabilities</h2>
            <ul>
              <li>Reports that state that software is out of date/vulnerable without a proof-of-concept.</li>
              <li>Vulnerabilities as reported by automated tools/scanners without additional analysis and validation.</li>
              <li>SSL/TLS scan reports.</li>
              <li>Open ports without an accompanying proof-of-concept demonstrating vulnerability</li>
              <li>Subdomain takeovers - please demonstrate that you are able to take over the page by leaving a non-offensive message, such as your username.</li>
              <li>CSV injection</li>
              <li>Best practices concerns</li>
              <li>Exposed login panels</li>
              <li>Self XSS</li>
              <li>Content/Text injection issues</li>
              <li>Missing cookie flags on non-authentication cookies</li>
              <li>Missing/Insufficient SPF, DKIM or DMARC record</li>
              <li>Unauthenticated Cross-site Request Forgery or any CSRF with minimal security implications [logout/login]</li>
              <li>Reports that affect only outdated user agents or app versions -- we only consider exploits in the latest browser versions for Safari, FireFox, Chrome and Edge.</li>
              <li>Issues that require physical access to a victim’s computer/device.</li>
              <li>Missing Custom Error Page</li>
              <li>Stack traces</li>
              <li>Attacks requiring MITM attack.</li>
              <li>Any kind of Denial of Service testing.</li>
              <li>Path disclosure</li>
              <li>Directory listings</li>
              <li>Clickjacking with no sensitive actions.</li>
              <li>Banner grabbing issues (figuring out what web server we use, etc.)</li>
              <li>Host header injections without a demonstrable impact.</li>
              <li>Vulnerabilities in third party software identified without proof of concept</li>
              <li>Vulnerabilities requiring extensive or unlikely user actions.</li>
              <li>Autocomplete Enabled.</li>
              <li>Enumerations of any kind: UUID, Invite/Voucher/Promo code, etc.</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div class="col-md-6">
            <h2>Confidentiality</h2>
            <p>Any data you receive, obtain access to or collect about Careem, Careem affiliates or any Careem users, customers, captains, employees or agents in connection with the Responsible Disclosure Program is considered as Careem’s confidential information ("Confidential Information").</p>
            <p>Confidential Information must be kept confidential and only used: (i) to make the disclosure to Careem under the Careem Responsible Disclosure Program; or (ii) to provide any additional information that may be required in relation to the disclosure. No further use or exploitation of Confidential Information is allowed. Upon Careem's request, you will permanently erase all Confidential Information for any systems and devices.</p>
            <p>You may not use, disclose or distribute any such Confidential Information, including without limitation any information regarding your Responsible Disclosure submission, without our prior written consent. You must get written consent by submitting a disclosure request to Careem. Please note, not all requests for public disclosure will be approved.</p>
            <p>The confidentiality obligations shall survive for a period of five (5) years from date of your receipt access or collection of Confidential Information.</p>
          </div>
          <div class="col-md-6">
            <h2>Acknowledgments</h2>
            <p>By making a Submission, you represent and warrant that:</p>
            <ul>
              <li>The Submission is original to you and you have the right to submit the Submission</li>
              <li>You will not violate any other applicable laws or regulations in connection with the Vulnerability or Careem Data</li>
              <li>You have not disclosed the Vulnerability to any third parties</li>
            </ul>
            <p>By making a Submission, you give us the right to use your Submission for any purpose.</p>
            <p>An acknowledgments page (Hall of Fame) would be created to thank the researchers who helped make Careem secure.</p>
          </div>
        </div>
        <div className="row">
          <div class="col">
            <h2>Rights and Licenses</h2>
            <p>We may modify the Program Terms or cancel the Responsible Disclosure Program at any time.</p>
          </div>
        </div>
        <div className="row">
          <div class="col">
            <h2>Suggested Report Template</h2>
            <p>The following is an example of a good report.</p>
            <div class="card">
              <div class="card-body">
                <code style={{"color":"black"}}>
                  ## Summary<br/>
                  Reflected XSS in app.careem.com<br/>
                  <br/>
                  ## Security Impact<br/>
                  Since app.careem.com has an authenticated experience, an XSS in this domain means an attacker can forge authenticated requests on behalf of the victim. Also, advanced phishing attacks and defacement are possible.<br/>
                  <br/>
                  ## Reproduction Steps<br/>
                  This is a reflected XSS in `app.careem.com` via the `track` querystring parameter via the route `/rides`.<br/>
                  * login to app.careem.com<br/>
                  * navigate to following URL:<br/>
                  &nbsp;&nbsp;* `https://app.careem.com/rides?track=&lt;script&gt;alert(document.domain);&lt;/script&gt;`<br/>
                  * note the alert box containing `app.careem.com`<br/>
                  <br/>
                  ## Specifics<br/>
                  * test account: `h4x0r@gmail.com`<br/>
                  * domain: `app.careem.com`
                  <br/>
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Guidelines;
