import React from 'react';
import {
  Link
} from "react-router-dom";
import ReactMde, { commands } from "react-mde";
import MarkdownIt from "markdown-it";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import "react-mde/lib/styles/css/react-mde-all.css";
import uuidv4 from 'uuid/v4';
import FileDrop from './FileDrop';
import { File } from 'react-feather';
import { Form } from 'react-bootstrap';
import { Helmet } from "react-helmet";

const md = new MarkdownIt().disable([ 'link', 'image' ]);

function Report(props) {
  const [errors, setErrors] = React.useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = props;

  const update = (field, value) => {
    ref.setReport({
      ...ref.report,
      [field]: value,
    })
  };

  const listCommands = [{
    commands: [
      commands.boldCommand,
      commands.italicCommand,
    ]
  }];

  const severities = ["None", "Low", "Medium", "High", "Critical"];

  const addFiles = (files) => {
    const all = ref.report.files;

    console.log(ref.report);

    files.forEach((v,k) => {
      const id = uuidv4();
      all[id] = v;
    });

    ref.setReport({
      files: all,
      ...ref.report,
    });
  }

  const removeFile = (id) => {
    const all = ref.report.files;
    delete all[id];
    ref.setReport({
      ...ref.report,
      files: all,
    });
  }

  const submit = (e) => {
    e.preventDefault();

    let promises = [];
    promises.push(executeRecaptcha("report_page"));
    Object.keys(ref.report.files).forEach((key) => {
      let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsDataURL(ref.report.files[key]);
          reader.onload = () => resolve({
            "name": ref.report.files[key].name,
            "content": reader.result.split(',')[1],
          });
      });
      promises.push(filePromise);
    });

    // loading
    update("loading", true);

    Promise.all(promises).then(results => {
        const token = results[0];
        let files = {};
        if (results.length > 1) {
          for (let i = 1; i < results.length; i++) {
            const f = results[i];
            files[f["name"]] = f["content"];
          }
        }

        fetch('https://9rz0fxtn99.execute-api.eu-west-1.amazonaws.com/report', {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            terms: ref.report.terms,
            token: token,
            files: files,
            title: ref.report.title,
            email: ref.report.email,
            summary: ref.report.summary,
            severity: ref.report.severity,
          })
        })
        .then(response => {
          if (!response.ok) { throw response }
          return response.json()
        })
        .then(data => {
          setErrors({});
          ref.setReport({
            ...ref.report,
            submitted: true,
            loading: false,
            reference: data.key,
          })
        })
        .catch(err => {
          try {
            err.json().then(data => {
              if (typeof data.message !== 'undefined') {
                setErrors({
                  "files": data.message
                });
              } else {
                setErrors(data.errors);
              }
            })
          } catch (e) {
            setErrors({
              "files": "An unknown error occurred."
            });
          }
          update("loading", false);
        });
    });
  }

  if (ref.report.submitted) {
    return (
      <div className="container py-4">
        <h1>Thank You</h1>
        <hr />
        <p>Your report has been submitted.</p>
        <p>Thank you for your taking the time out to write and submit the report. Your reference ID is <strong className="careem-primary">{ref.report.reference}</strong>. We will reach out to you at <strong className="careem-primary">{ref.report.email}</strong> and send you a confirmation.</p>
        <button className="btn btn-primary my-2 careem-button-primary" onClick={ref.resetReport}>
          Report another Vulnerability
        </button>
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Careem - Submit Vulnerability Report</title>
        </Helmet>
        <div className="container py-4">
          <h1>Submit Vulnerability Report</h1>
          <hr />
          <p>You're about to submit a report to Careem. Provide as much information as possible about the potential issue you have discovered. The more information you provide, the quicker we will be able to validate the issue. If you haven't yet, please remember to review our <Link to="/guidelines" className="careem-primary">Disclosure Guidelines</Link>.</p>
          <Form>
            <Form.Group controlId="inputEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={ref.report.email}
                onChange={(v) => update("email", v.target.value)}
                isInvalid={!!errors.email}
                autoComplete="off"
                required
              />
              <Form.Text className="text-muted">
                Enter the email through which we would contact you. We'll never share your email with anyone else.
              </Form.Text>
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="inputTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={ref.report.title}
                onChange={(v) => update("title", v.target.value)}
                isInvalid={!!errors.title}
                autoComplete="off"
                required
              />
              <Form.Text className="text-muted">
                A clear and concise title includes the type of vulnerability and the impacted asset.
              </Form.Text>
              <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="inputSeverity">
              <Form.Label>Severity</Form.Label>
              <div className="btn-group" style={{display: "block"}} role="group" aria-label="Basic example">
                {severities.map((v,k) => <button key={k} type="button" className={`btn btn-secondary btn-sm ${ref.report.severity === v ? "active" : ""}`} onClick={() => update("severity", v)} >{v}</button>)}
              </div>
              <Form.Text className="text-muted">
                Estimate the severity of this issue.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="inputDescription">
              <Form.Label>Description</Form.Label>
              <ReactMde
                value={props.report.summary}
                onChange={(v) => update("summary", v)}
                selectedTab={props.report.tab}
                onTabChange={(v) => update("tab", v)}
                generateMarkdownPreview={markdown =>
                  Promise.resolve(md.render(markdown))
                }
                commands={listCommands}
              />
              <Form.Text className="text-muted">
                What is the vulnerability? In clear steps, how do you reproduce it?
              </Form.Text>
              { !!errors.summary && <div className="invalid-feedback" style={{display: "block"}}>{errors.summary}</div> }
              <hr />
              {Object.keys(ref.report.files).map((v,k) =>
                <p key={k}><File /> {ref.report.files[v].name} ({ref.report.files[v].size} bytes) - <button type="button" className="btn btn-link p-0" onClick={() => removeFile(v)}>(Remove)</button></p>
              )}
              <FileDrop addFiles={addFiles} />
              { !!errors.files && <div className="invalid-feedback" style={{display: "block"}}>{errors.files}</div> }
            </Form.Group>
            <Form.Group controlId="inputCheck">
              <Form.Check
                required
                label="By checking this box, you confirm that you have read and the Careem Responsible Disclosure Program Terms and agree to be bound by the Program Terms."
                feedback={errors.terms}
                isInvalid={!!errors.terms}
                checked={ref.report.terms}
                onChange={(e) => update("terms", e.target.checked)} 
              />
            </Form.Group>
              <button disabled={ref.report.loading} onClick={submit} className="btn btn-primary careem-button-primary">{ ref.report.loading ? 'Please wait...' : 'Submit Report' }</button>
          </Form>
        </div>
      </>
    );
  }
}

export default Report;
