import React from 'react';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Careem - Vulnerability Disclosure Program</title>
      </Helmet>
      <section className="jumbotron text-center">
        <div className="container">
          <h1 className="jumbotron-heading">Sec@Careem</h1>
          <p className="lead text-muted">The security of our platform, data of our captains, customers, and colleagues is our top priority.</p>
          <p>
            <Link to="/report" className="btn btn-primary my-2 careem-button-primary">
              Report a Vulnerability
            </Link>
          </p>
        </div>
      </section>
      <div className="container">
        <blockquote class="blockquote">
          <p class="mb-0">Careem is the internet platform for the greater Middle East region. A pioneer of the region's ride-hailing economy, Careem is expanding services across its platform to include mass transportation, delivery and payments to become the region's everyday SuperApp.</p>
        </blockquote>
        <p>The security of our platform, data of our captains, customers, and colleagues is our top priority. While we strive to test and secure everything as well as possible, we appreciate any responsibly disclosed information regarding potential vulnerabilities or security issues in our products, systems or assets (each a "Vulnerability"). </p>
        <p>Before finding and reporting any Vulnerabilities you are required to read and agree to the Responsible Disclosure Program Terms (the "Program Terms").  In these terms reference to "you" or "researcher" refer to a researcher that submits a responsible disclosure in accordance with the Careem Responsible Disclosure Program Terms and "we" or "us" refers to Careem.</p>
      </div>
    </>
  )
}

export default Home;