import React from 'react'
import {useDropzone} from 'react-dropzone'

function FileDrop(props) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: props.addFiles})

  return (
    <div className={`col dropzone ${isDragActive ? 'dropzone-active' : ''}`} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="dropzone-placeholder">
      {
        isDragActive ?
          <>Drop the files here ...</> :
          <>Drag &amp; drop <span className="careem-primary">or select more files from your computer (max. 10MB combined)</span></>
      }
      </div>
    </div>
  )
}

export default FileDrop;