import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import Logo from './Logo';
import Home from './Home';
import Report from './Report';
import Guidelines from './Guidelines';
import HallOfFame from './HallOfFame';

function App() {
  const defaultReport = {
    tab: "write",
    email: "",
    title: "",
    severity: "None",
    summary: "## Summary\nSummary of the issue being reported goes here\n\n## Security Impact\nDescribe the impact of the issue\n\n## Reproduction Steps\nList all of the reproduction steps\n\n## Specifics\n* test account: account used\n* domain: target domain",
    terms: false,
    files: {},
    submitted: false,
    loading: false,
    reference: "",
  };

  const [report, setReport] = React.useState(defaultReport);
  const resetReport = () => {
    setReport(defaultReport);
  }

  return (
    <Router>
      <header className="bg-careem">
        <div className="container d-flex justify-content-between">
          <nav className="navbar navbar-expand-lg navbar-light bg-careem w-100">
            <div className="mr-auto w-100 order-1 order-lg-0 justify-content-start">
              <Logo/>
            </div>
            <div className="mx-auto order-0 w-100">
              <span className="careem-regular text-white title">
                Vulnerability Disclosure Program
              </span>
            </div>
            <div className="order-3 w-100">
              <ul className="navbar-nav ml-auto justify-content-end">
                <NavLink className="nav-item" activeClassName="active" exact to="/">
                  <a className="nav-link">Home</a>
                </NavLink>
                <NavLink className="nav-item" activeClassName="active" to="/report">
                  <a className="nav-link">Report</a>
                </NavLink>
                <NavLink className="nav-item" activeClassName="active" to="/guidelines">
                  <a className="nav-link">Guidelines</a>
                </NavLink>
                <NavLink className="nav-item" activeClassName="active" to="/hall-of-fame">
                  <a className="nav-link">Hall of Fame</a>
                </NavLink>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <main role="main">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/report">
            <Report report={report} setReport={setReport} resetReport={resetReport} />
          </Route>
          <Route path="/guidelines">
            <Guidelines />
          </Route>
          <Route path="/hall-of-fame">
            <HallOfFame />
          </Route>
        </Switch>
      </main>
      <footer className="text-muted">
        <div className="container">
          <hr />
          <p>&copy; Careem</p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
