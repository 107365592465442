import React from 'react';
import { Star } from 'react-feather';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";

function HallOfFame() {
  const [reporters] = React.useState(window.researchers);

  const data = Object.keys(reporters).map(key => 
    <div>
      <div class="row">
        <div class="col-md-2">
          <h4>{key}</h4>
        </div>
      </div>
      <div class="row">
        {reporters[key].map((item, i) => (
          <div key={i} class="col-md-2">
            {item.link ? (
              <a className="careem-primary" target="_blank" rel="noopener noreferrer" href={item.link}>{item.name}</a>
            ) : (
              <>{item.name}</>
            )}
            {item.reports > 1 && (
              <small> x {item.reports}</small>
            )}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      <Helmet>
        <title>Careem - Hall of Fame</title>
      </Helmet>
      <section className="jumbotron text-center">
        <div className="container">
          <h1 className="jumbotron-heading">Hall of Fame</h1>
          <p className="lead text-muted">We would like to thank all organizations and hackers who helped us, our captains and our customers stay safe and secure by responsibly disclosing security issues affecting our services.</p>
        </div>
      </section>
      <div className="container">
        {Object.keys(reporters).length > 0 && data}
        {Object.keys(reporters).length <= 0 && (
          <div class="row d-flex justify-content-center">
            <p>Be the <strong>first one</strong> to be listed in our Hall of Fame. <Link to="/report" className="careem-primary">Report a Vulnerability.</Link></p>
          </div>
        )}
        <div class="row py-5">
          <div class="col text-center">
            <Star size="150" style={{color: "lightgray"}} />
          </div>
        </div>
      </div>
    </>
  )
}

export default HallOfFame;